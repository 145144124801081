.App {
  text-align: center;
}

p {
  margin: 0;
  padding: 0;
}




