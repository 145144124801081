#form-container {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 400px;
}

#logo {
    background-image:url('../assets/Frame.png');
    background-size: auto;
    background-repeat: no-repeat;

    height: 100px;
    width: 100%;
}


#input-container{
margin: 20px 0 20px;
}

label{
    display: flex;
    flex-direction: column;
    margin: 50 0 50 0;
}

input{
    display: flex;
    width: 90%;
    align-items: flex-start;
    height: 25px;
    margin : 5px 0;
    border:1px solid;
    border-radius: 4px;
    border-color: blue;
}

.valid-input{
   border-color:green;
}
.invalid-input{
   border-color:red;
}

button {
    background-color: #E64D6D;
    color: white;
    border: none;
    flex-direction: 'row';
    align-items : 'center';
    justify-content: 'center';
    border-radius: 4px;
    width: '100%';
    height: 40px;
    width: 140px;
}

#valid{
    background-color: #E64D6D;

}

#not-valid{
    background-color: gray;
}